/*
	These are application-wide styles. To style DOM elements, use JSS
*/

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}
